<template>
    <v-layout fill-height>
        <div :class="`conBox ${isAdmin? `admin`:`popupList`}`"> 
            <!--검색&등록버튼-->
            <div class="srhTop" v-if="isAdmin">
                <div :class="'srhBox '+srhBoxFocus">
                    <label class="sel">
                        <select v-model="APIList.APIReq.searchType" @change="APIList.APIReq.searchQuery = '';">
                            <option v-for="(item, i) in searchItems" :key="i" :value="item.value">{{ item.name }}</option>
                        </select>
                    </label>
                    <label class="tex">
                        <input type="text" placeholder="검색어를 입력해주세요." v-model="APIList.APIReq.searchQuery" @focus="srhBoxFocus='focusOn'" @blur="srhBoxFocus=''" @keyup.enter="APIList.APIReq.pageNo=1;getList();">
                    </label>
                    <button class="srhBtn btn solid" v-ripple @click="search"><i class="micon">search</i></button>
                </div>
                <button v-if="isAdmin" class="btn solid" @click="checkActivatedUserCnt()" v-ripple id="gd_regBtn">사용자 계정 생성</button>
            </div>
            <div class="divBox">
                <!--체크박스-->
                <div class="checkboxArea" v-if="isAdmin">
                    <p>활성화된 계정 수: <span>{{ activeUserCnt }}</span> / {{userMaxCnt}}</p>
                    <div>
                        <span class="labelText">활성화된 계정만</span>
                        <v-checkbox v-model="APIList.APIReq.onlyActive" @change="chgOnlyActive" color="indigo" label="" value="true" hide-details></v-checkbox>
                    </div>
                </div>
                <!--//체크박스-->
                <div class="borderWrap">
                    <div class="tblScroll tblFixed" ref="scrollContainer" @scroll="handleScroll">
                        <table class="tbl">
                            <caption>사용자 계정 목록</caption>
                            <colgroup>
                                <col style="width: 10%" v-if="!isAdmin">
                                <col >
                                <col style="width:22%">
                                <col style="width:18%" v-if="isAdmin">
                                <col style="width:20%">
                                <col style="width:12%" v-if="isAdmin">
                            </colgroup>
                            <thead>
                                <tr>
                                <th style="cursor:pointer;" v-for="(item, i) in filteredUserHeader" :key="i" @click="setSortType(`${item.value}`, $event)" v-ripple>
                                    {{ item.name }} <span class="icon" v-if="(item.value != '' && item.value != 'isActive' && item.value != 'role')"></span>
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="isGetingItems">
                                <tr v-for="n in APIList.preSize" :key="n">
                                    <td v-for="na in isAdmin? 5:4" :key="na" style="padding:0 1rem;">
                                        <ContentLoader :speed="1" width="100" :height="12">
                                            <rect x="15" y="3" rx="3" ry="3" :width="70" :height="7" />
                                        </ContentLoader>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="!isGetingItems && APIList.resultList == 0">
                                <tr>
                                    <td :colspan="isAdmin?`5`:`4`">조회 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredUserList" :key="index" @click="selectedUser(item.no);">
                                    <td v-if="!isAdmin" class="text-center">
                                        <label><input type="radio" name="radi" class="selRadi" v-model="transfereeNo" :value="item.no"></label>
                                    </td>
                                    <td class="text-center">{{ item.loginId }}</td>
                                    <td class="text-center">{{ item.userNm }}</td>
                                    <td v-if="isAdmin" class="text-center">{{ item.role === "A" ? `병원관리자` : `일반사용자` }}</td>
                                    <td>{{ item.crtDtStr }}</td>
                                    <td v-if="isAdmin">
                                        <v-chip :class="item&&getStateChipClass(item.isActive)">{{ item.isActive? `활성화`:`비활성화` }}</v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="openPopup" width="320">
            <DefaultPopup
                :popupText="'해당 사용자의 상태를 변경하시겠습니까?'"
                :secondTxt="CONST_OK"
                :firstBtn="true"
                :onClick="() => {  changeStateProgress();  }"
                @closePopup="openPopup = false"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import API_USERS from '@/API/user/list';
import API_CHANGE_STATE from '@/API/user/changeState';
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    },
    props: {
        isAdmin: {
            default: false
        },
    },
    data() {
        return {
            onDetail: false,
            isGetingItems: false,
            APIList: {
                APIReq: {
                    pageNo: 1,
                    pageSize: 300,
                    searchType: 'loginId',
                    searchQuery: '',
                    isPagingList: false,
                    onlyActive: false
                },
                resultList: [],
                total: 0,
                preSize: 1,
            },
            searchItems: [
                { name: 'ID', value: 'loginId' },
                { name: '이름', value: 'userNm' },
            ],
            userHeader: [
                { name: '선택', value: '', isAdmObj: false },
                { name: 'ID', value: 'loginId'},
                { name: '이름', value: 'userNm'},
                { name: '권한', value: 'role', isAdmObj: true},
                { name: '생성일시', value: 'crtDt'},
                { name: '계정상태', value: 'isActive', isAdmObj: true},
            ],
            userMaxCnt: process.env.VUE_APP_USER_MAX_CNT,
            activeUserCnt: 0,
            openPopup: false,
            selectedNo: null,
            selectedIndex: null,
            sortBy: [],
            loading: false,
            reachedEnd: false,
            srhBoxFocus: '',
            transfereeNo: null
        };
    },
    watch: {
        $route(to) {
            if (to.name == 'userManage') this.getList();
        },
    },
    created() { },
    mounted() {
        this.$store.dispatch("page/setSearchType", this.APIList.APIReq.searchType);
        this.APIList.APIReq.onlyActive = this.$store.getters['page/onlyActive'];
        this.APIList.APIReq.searchType = this.$store.getters['page/searchType'];
        this.APIList.APIReq.searchQuery = this.$store.getters['page/searchQuery'];
        this.getList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        this.initSearch()
    },
    methods: {
        search() {
            if (!this.APIList.APIReq.searchType) {
                this.showPopup('검색조건을 선택해주세요.');
                return false;
            }
            this.APIList.APIReq.pageNo = 1;
            this.scrollToTop();
            this.getList();
        },
        async getList() {
            if (this.isGetingItems) {
                return;
            }
            this.APIList.resultList=[];
            this.isGetingItems = true;
            let res = await API_USERS.request(this.APIList.APIReq.pageNo, this.APIList.APIReq.pageSize, this.APIList.APIReq.searchType, this.APIList.APIReq.searchQuery, 
                this.APIList.APIReq.onlyActive, this.sortBy, this.isAdmin);
            if (res.isSuccess) {
                this.APIList.resultList = res.userList;
                this.APIList.total = res.total;
                this.activeUserCnt = res.activeUserCnt;
                localStorage.setItem("activeUserCnt", this.activeUserCnt);
                this.APIList.preSize = Math.max(1, this.APIList.resultList.length);
                this.APIList.preSize = Math.min(13, this.APIList.resultList.length);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.$store.dispatch("page/setSearchType", this.APIList.APIReq.searchType);
            this.$store.dispatch("page/setSearchQuery", this.APIList.APIReq.searchQuery);
            this.$store.dispatch("page/setOnlyActive", this.APIList.APIReq.onlyActive);
            this.isGetingItems = false;
        },
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;
            const bottomOffset = 900;
            if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
                this.loadMoreList();
            }
        },
        async loadMoreList() {
            if (this.loading || this.reachedEnd) return;
            if(this.APIList.resultList.length < this.APIList.APIReq.pageSize) return;
            this.loading = true;
            const nextPage = this.APIList.APIReq.pageNo + 1;
            let res = await API_USERS.request(this.APIList.APIReq.pageNo, this.APIList.APIReq.pageSize, this.APIList.APIReq.searchType, this.APIList.APIReq.searchQuery, 
                this.APIList.APIReq.onlyActive, this.sortBy, this.isAdmin);
            if (res.isSuccess) {
                this.APIList.resultList = [...this.APIList.resultList, ...res.list];
                this.APIList.APIReq.pageNo = nextPage;
                if (res.list.length < this.APIList.APIReq.pageSize) {
                    this.reachedEnd = true;
                }
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.loading = false;
        },
        scrollToTop() {
            this.$refs.scrollContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            this.reachedEnd = false;
        },
        setSortType(item, event) {
            if(item === 'role' || item === 'isActive' ) return;
            const classNames = ['up', 'down'];
            let order = '';
            let targetElement = event.target;
            if (targetElement.className.includes('icon')) targetElement = targetElement.closest('th');
            const isUpClass = classNames.includes(targetElement.className);
            if (isUpClass) {
                order = targetElement.className === 'up' ? 'desc' : 'init';
                targetElement.className = targetElement.className === 'up' ? 'down' : 'default';
            } else {
                order = 'asc';
                targetElement.className = 'up';
            }

            const existingIndex = this.sortBy.findIndex(obj => obj.key === item);
            if (existingIndex !== -1) {
                if (order === 'init') {
                    this.sortBy.splice(existingIndex, 1);
                } else {
                    this.sortBy[existingIndex].order = order;
                }
            } else {
                this.sortBy.push({ key: item, order: order });
            }
            this.APIList.APIReq.pageNo = 1;
            this.scrollToTop();
            this.getList();
        },
        async selectedUser(no) {
            if(this.isAdmin){
                this.$router.push({name: 'userDetail',params: { no: no }})
            }else{
                this.transfereeNo = no;
                this.$emit('setTransfeeNo', this.transfereeNo);
            }
        },
        changeUserState(no, index){
            this.selectedNo = no;
            this.selectedIndex = index;
            this.openPopup = true
        },
        changeStateProgress() {
            if (!this.selectedNo) {
                this.showPopup("선택한 계정이 없습니다.");
                return;
            }
            const nowState = this.APIList.resultList[this.selectedIndex].isActive;
            if (nowState === false) {
                if (parseInt(this.userMaxCnt) < parseInt(this.activeUserCnt)) {
                    this.showPopup('최대 활성화 계정 개수가 초과하여 해당 계정의 상태를 바꿀 수 없습니다.');
                    this.openPopup = false;
                }else{
                    this.getNewState();
                }
            } else {
                this.getNewState();
            }
        },
        async getNewState(){
            let res = await API_CHANGE_STATE.request(this.selectedNo);
            if (res.isSuccess) {
                this.getList();
                this.openPopup = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
        },
        checkActivatedUserCnt(){
            const activeUserCnt = localStorage.getItem('activeUserCnt');
            if(activeUserCnt) this.activeUserCnt = activeUserCnt;
            if(parseInt(this.userMaxCnt) <= parseInt(this.activeUserCnt)) {
                this.showPopup('활성화된 계정 수가 기준을 초과했습니다.<br>확인 후 다시 시도해주세요.')
            }else{
                this.$router.push({name:'userRegist'});
            } 
        },
        getStateChipClass(isActive){
            if(isActive) return 'active'
            else return 'nonActive'
        },
        chgOnlyActive(){
            if(this.APIList.APIReq.onlyActive == null) this.APIList.APIReq.onlyActive = false
            this.APIList.APIReq.pageNo = 1; 
            this.getList(); 
            this.scrollToTop();
        }
    },
    computed: {
        filteredUserHeader() {
            if (this.isAdmin) {
                return this.userHeader.filter(item => item.isAdmObj !== false);
            }else{
                return this.userHeader.filter(item => item.isAdmObj !== true);
            }
        },
        filteredUserList(){
            if(this.isAdmin){
                return this.APIList.resultList;
            }else{
                return this.APIList.resultList.filter(item => item.no !== this.userInfo.no)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tblScroll{
    max-height: 655px;
    .tbl{
        border-bottom: 0;
        tbody tr{
            &:hover{
                cursor: pointer;
            }
        }
    }
}

.srhTop .srhBox{width: 100%;}
.conBox.admin .srhTop .srhBox{width: calc(70% - 20px)}
.conBox.admin{padding: 40px;}
.conBox.admin .divBox{margin-top:30px;}

.conBox.popupList{height: auto; padding: 0; border:1px solid #e5e5e5; border-radius: var(--border);}
.conBox.popupList .borderWrap{border-radius: 0;}
.conBox.popupList .tblScroll{max-height: 300px;}

.pointColor{
    color: var(--color-point);
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.conBox.popupList{
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    .tbl{border-radius: 0; box-shadow: none; border-left: 0; border-right: 0;}
    .tbl thead th{font-size: 14px; padding: 12px;}
    .tbl td{height: auto; padding: 12px; font-size: 14px;}

}

::v-deep .v-input{margin-top: 0; padding-top: 0;}
::v-deep .v-input__control{flex-direction: inherit; flex-grow: inherit; flex-wrap: nowrap;}
::v-deep .v-input__slot{justify-content: center;}
::v-deep .theme--light.v-label{color: rgba(0, 0, 0, 0.87); font-size: 1.4rem; display: flex; flex:none}

.v-chip.active{background-color:#EBFAFC; color: #00BAD3;}
.v-chip.nonActive{background-color:#F5F5F5; color: #666;}

.divBox .checkboxArea{
    margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center;
    >p{text-align: right; color: rgba(236, 240, 241); font-size: 14px;}
    >p span{font-weight: bold;}
    >div{display: flex; align-items: center;}
    .labelText{color:  #fff; margin-right: 7px; font-size: 14px;}
    ::v-deep .v-input__slot{justify-content: flex-end;}
    ::v-deep .theme--light.v-label{color: #fff; font-size: 1.4rem; display: flex; flex:none}
    ::v-deep .theme--light.v-icon{color: rgba(255, 255, 255, 0.75)}
}


</style>
